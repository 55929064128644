import { template as template_ab5252a7f8d745b6956cb3b587f3ecea } from "@ember/template-compiler";
const WelcomeHeader = template_ab5252a7f8d745b6956cb3b587f3ecea(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
